.text {
    background: -webkit-linear-gradient(rgb(3 92 187), #62b3c0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: #6d869d;
}

.title {
    background: -webkit-linear-gradient(rgb(0, 92, 190), rgb(91, 239, 255));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.first {
    background: linear-gradient(#64bde6, #6d869d);
}

.secound {
    background: linear-gradient(#6d869d, #64bde6);
}