@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    ul,
    ol {
        list-style: inside;
    }
    li {
        margin-left: 3rem;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

iframe {
    position: fixed;
    background: #000;
    border: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.react-pdf__Page__canvas {
    margin: 2rem auto 0 auto;
    width: 80% !important;
}

.react-pdf__Page__annotations,
.react-pdf__Page__textContent {
    display: none;
}