.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}
iframe {
    border: none;
    width: 100%;
    height: 500px;
  }

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#ResumeContainer {
    margin: auto;
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PDFPage {
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
}

.PDFPageOne {
    margin-bottom: 25px;
}

.PDFPage>canvas {
    max-width: 100%;
    height: auto !important;
}

.test {
    color: red
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
}