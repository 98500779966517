.container {
  position: relative;
}

@media (min-width: 1024px) {
  .content_viewport {
    border: 0px none;
    height: 900px;
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .content_viewport {
    border: 0px none;
    height: 900px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .content_viewport {
    border: 0px none;
    height: 900px;
    width: 100%;
  }
}
